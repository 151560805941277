<template>
  <div
    v-if="this.$route.params.response === 'success'"
    class="-mt-16 h-screen flex flex-col justify-center items-center"
  >
    <font-awesome-icon
      class="text-9xl text-green-500"
      :icon="['fas', 'check']"
    />
    <div class="mt-5 text-xl text-green-500 text-center px-1">
      Thank you for reaching out! We will get back to you shortly.
    </div>
    <div class="flex flex-row justify-center flex-wrap mt-10 gap-2">
      <router-link to="/contact-us">
        <button
          :class="[
            'h-12',
            'w-64',
            'border-2',
            'font-semibold',
            'rounded-md',
            'flex-grow',
            'transition-colors',
            'duration-300',
            'border-gray-800',
            'text-gray-800',
            'hover:bg-gray-800',
            'hover:text-gray-50',
            'dark:border-gray-100',
            'dark:text-gray-100',
            'dark:hover:bg-gray-100',
            'dark:hover:text-cyan-1000',
          ]"
        >
          <font-awesome-icon class="mr-1" :icon="['fas', 'arrow-left']" />
          Request Another Quote
        </button>
      </router-link>
      <router-link to="/">
        <button
          @click="$router.push('/')"
          :class="[
            'h-12',
            'w-32',
            'border-2',
            'font-semibold',
            'rounded-md',
            'flex-grow',
            'transition-colors',
            'duration-300',
            'border-gray-800',
            'text-gray-800',
            'hover:bg-gray-800',
            'hover:text-gray-50',
            'dark:border-gray-100',
            'dark:text-gray-100',
            'dark:hover:bg-gray-100',
            'dark:hover:text-cyan-1000',
          ]"
        >
          Home
          <font-awesome-icon
            class="ml-1 transform rotate-180"
            :icon="['fas', 'arrow-left']"
          />
        </button>
      </router-link>
    </div>
  </div>
  <div
    v-else-if="this.$route.params.response === 'error'"
    class="-mt-16 h-screen flex flex-col justify-center items-center"
  >
    <font-awesome-icon class="text-9xl text-red-500" :icon="['fas', 'times']" />
    <div class="mt-5 text-xl text-red-500 text-center px-1">
      Our sincere apologies. We experienced a problem processing your request!
    </div>
    <div class="flex flex-row justify-center flex-wrap mt-10 gap-2">
      <router-link to="/contact-us">
        <button
          :class="[
            'h-12',
            'w-64',
            'border-2',
            'font-semibold',
            'rounded-md',
            'flex-grow',
            'transition-colors',
            'duration-300',
            'border-gray-800',
            'text-gray-800',
            'hover:bg-gray-800',
            'hover:text-gray-50',
            'dark:border-gray-100',
            'dark:text-gray-100',
            'dark:hover:bg-gray-100',
            'dark:hover:text-cyan-1000',
          ]"
        >
          <font-awesome-icon class="mr-1" :icon="['fas', 'arrow-left']" />
          Try Again
        </button>
      </router-link>
      <router-link to="/">
        <button
          @click="$router.push('/')"
          :class="[
            'h-12',
            'w-32',
            'border-2',
            'font-semibold',
            'rounded-md',
            'flex-grow',
            'transition-colors',
            'duration-300',
            'border-gray-800',
            'text-gray-800',
            'hover:bg-gray-800',
            'hover:text-gray-50',
            'dark:border-gray-100',
            'dark:text-gray-100',
            'dark:hover:bg-gray-100',
            'dark:hover:text-cyan-1000',
          ]"
        >
          Home
          <font-awesome-icon
            class="ml-1 transform rotate-180"
            :icon="['fas', 'arrow-left']"
          />
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactResponse",
  mounted() {
    if (!["success", "error"].includes(this.$route.params.response)) {
      this.$router.push("/");
    }
  },
};
</script>